import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
//获取项目下所有甘特图
export function getAllGanttChart(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts`,
    method: 'get',
    data: data,
  })
}

//获得当前甘特图是否开通付费
export function getPayGantt(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/payFuture`,
    method: 'get'
  })
}

//新建gantt图
export function addGanttChart(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts`,
    method: 'post',
    data: data
  })
}

//删除gantt图
export function deleteGanttChart(projectId, ganttChartId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/${ganttChartId}`,
    method: 'delete',
  })
}

// 甘特图中节点的获取
export function getGanttNode(projectId, ganttChartId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/${ganttChartId}/elements`,
    method: 'get'
  })
}

//获取依赖关系
export function getGanttNodeAndDepend(projectId, ganttChartIds) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/elements/withRelation`,
    method: 'post',
    data: ganttChartIds
  })
}
// 添加依赖关系
export function addGanttNodeAndDepend(projectId, params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/elements/relation`,
    method: 'post',
    data: params
  })
}

// 删除依赖关系
export function deleteGanttNodeAndDepend(projectId, params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/elements/relation`,
    method: 'delete',
    data: params
  })
}
//获得节点信息
export function searchByKeys(params) {
  // tag 无影响
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/searchByKeys`,
    data: params.data,
    method: 'post',
  })
}
//修改起始结束时间
export function changeAllTime(params) {
  // tag 无影响
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ganttCharts/elements/${params.ganttItemUuid}/offset`,
    data: params.data,
    method: 'put',
  })
}

//修改起始结束时间为空
export function ClearGanttOffsetTime(params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/gantt/${params.ganttItemUuid}/offset`,
    method: 'delete',
  })
}

//修改起始时间
export function changeStartTime(params) {
  // tag 无影响
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ganttCharts/elements/${params.ganttItemUuid}/startTime`,
    data: params.data,
    method: 'put',
  })
}
//修改结束时间
export function changeDueTime(params) {
  // tag 无影响
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ganttCharts/elements/${params.ganttItemUuid}/dueTime`,
    data: params.data,
    method: 'put',
  })
}
//修改进度条
export function changeProgress(params) {
  // tag 无影响
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ganttCharts/elements/${params.ganttItemUuid}/progress?progress=${params.progress}`,
    method: 'put',
  })
}
//填写工作时间
export function addTimeProgress(params) {
  // tag 无影响
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ganttCharts/elements/${params.ganttItemUuid}/manHour?manHour=${params.manHour}`,
    method: 'put',
  })
}
//填写计划工时
export function addWorkTimeProgress(params) {
  // tag 无影响
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/gantt/${params.ganttItemUuid}/planManHour/${params.planManHour}`,
    method: 'put',
  })
}
//计算进度
export function calcTimeProgress(params) {
  // tag 无影响
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ganttCharts/elements/${params.ganttItemUuid}/calProgress`,
    method: 'put',
  })
}
// 获取时间轴
export function getTimeAxis(params) {
  // tag 无影响
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/gantt/time/${params.ganttChartId}/`,
    method: 'get',
  })
}

// 批量导入
export function postNodeList(params) {
  // tag 无影响
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ganttCharts/elements/insert/batch`,
    method: 'post',
    data: params.ganttItemList
  })
}

// 批量删除
export function deleteNodeList(params) {
  // tag 无影响
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ganttCharts/${params.ganttChartId}/elements/batch`,
    method: 'delete',
    data: params.ganttItemIdList
  })
}

//批量修改
export function changeTimeList(params) {
  // tag 无影响
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ganttCharts/${params.ganttChartId}/elements/location`,
    method: 'put',
    data: params.changeTimeList
  })
}


//更改节点位置
export function changePosition(projectId, params) {
  // tag 无影响
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/elements/batchAutoUpdateLocation`,
    method: 'put',
    data: params
  })
}

//获得起始时间
export function getTimeLength(projectId, ganttChartId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/${ganttChartId}/timeRange`,
    method: "get",
  });
}

//获得甘特图所有可指定的通知关系列表
export function getGanttRules() {
  return request({
    url: `/${serviceConfig['file-manage-service']}/ganttRules`,
    method: "get",
  });
}

//获得当前甘特图所有的通知关系列表
export function getSelectedGanttRules(projectId, ganttChartId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/${ganttChartId}`,
    method: "get",
  });
}

//修改当前甘特图所有的通知关系列表
export function setSelectedGanttRules(projectId, ganttChartId, params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/${ganttChartId}`,
    method: "put",
    data: params
  });
}

//模糊搜索
export function get_nodes_by_fuzzykey(projectId, fuzzyKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/notIncluded/nodes`,

    method: "get",
    params: {
      fuzzyKey,
    },
  });
}

//根据nodekey查询甘特图
export function get_ganttnode_by_node(projectId, nodeKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/elements/node/${nodeKey}`,

    method: "get",
  });
}


//画布页插入
export function insert_ganttnode_by_node(projectId, nodeKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/${ganttChartId}/elements/insert/batch`,

    method: "get",
  });
}

//节点移动
export function move_ganttnode_to_newchart(projectId, ganttItemUuid, targetGanttChartUuid, sourceGanttChartUuid) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/elements/${ganttItemUuid}/toAnotherChart?targetGanttChartUuid=${targetGanttChartUuid}&sourceGanttChartUuid=${sourceGanttChartUuid}`,
    method: "put"
  })
}

//添加节点,用于非甘特图页面。添加时会忽略已有节点
export function add_ganttnode_by_nodekey(projectId, ganttChartUuid, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/${ganttChartUuid}/elements/insert/batch`,
    method: "post",
    data: data
  })
}

export function get_file(projectId, fileId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/fileId/${fileId}`,
    method: "get",
  });
}

//删除甘特图中节点
export function delete_gantt_node(projectId, ganttChartId, ganttItemUuid) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/ganttCharts/${ganttChartId}/elements/${ganttItemUuid}`,
    method: "delete",
  });
}

//设置甘特图中节点的里程碑
export function setMilestone(projectId, ganttItemUuid, data) {
  return request({
    url: `/${serviceConfig['mindmap-service']}/${projectId}/gantt/${ganttItemUuid}/milestone`,
    method: "put",
    params: {
      milestone: data
    }
  });
}

//甘特图导出获取用户可选择字段
export function getExcelFields(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ganttCharts/export/excel/fields`,
    method: "GET"
  });
}
//甘特图导出接口
export function exportExcel(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ganttCharts/${params.ganttChartId}/excel`,
    data: params.data,
    method: "post"
  });
}
//甘特图导入excel 解析文件
export function importExcel(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ganttCharts/excel/resolution`,
    params: params.params,
    method: "POST"
  });
}
//甘特图导入excel
export function importLastStep(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ganttCharts/${params.ganttChartId}/excel/import`,
    data: params.data,
    method: "POST"
  });




}
//甘特图数据报表

//状态
export function getPlannedWorkingHoursStatus(params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/gantt/analyzeGanttStatus`,
    method: 'post',
    data: params.data
  })
}

export function getPlannedWorkingHoursAssignee(params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/gantt/analyzeGanttAssignee`,
    method: 'post',
    data: params.data
  })
}

export function getPlannedWorkingHoursTable(params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/gantt/analyzeGanttAssigneeStatus`,
    method: 'post',
    data: params.data
  })
}

export function getPlannedWorkingHoursTime(params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/gantt/analyzeGanttManHour`,
    method: 'post',
    data: params.data
  })
}
// 自动排期
export function autoSchedule(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ganttCharts/${params.ganttChartId}/autoSchedule`,
    data: params.data,
    method: 'post'
  })
}
// 批量获取甘特图
export function getBatchAnalyzeGanttStatus(params) {
  let query = ''
  params.data.ganttChartUuids.forEach(k => {
    query += `ganttChartUuids=${k}&`
  })
  const urlParams = query.substring(0, query.length - 1)
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/gantt/batchAnalyzeGanttStatus?${urlParams}`,
    method: 'get'
  })
}
// 交付物编辑
export function editGanttAttachment(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/ganttCharts/elements/ ${params.ganttItemUuid}/attachment`,
    data: params.data,
    method: 'put'
  })
}